<template>
    <v-container>
        <v-row >
            <v-card style="border-radius:5px" outlined elevation="1" min-width="100%">
                <v-list>
                    <v-list-item>
                        <v-list-item-icon class="mx-0 pr-3">
                            <v-avatar style="background: #E3E3E3;">
                                <span class="font-weight-bold primary--text">
                                    {{ (this.$store.getters.getFname.toString().charAt(0).toUpperCase()) }} {{ (this.$store.getters.getLname.toString().charAt(0).toUpperCase()) }}
                                </span>
                            </v-avatar>
                        </v-list-item-icon>
                        <v-list-item-content class="px-0 mx-0">
                            <v-col>
                                <v-row>
                                    <span class="font-weight-bold text-h6">
                                        Welcome back, {{ this.$store.getters.getFname }} {{ this.$store.getters.getLname }}
                                    </span>
    
                                </v-row>
                                <v-row>
                                    <span class="grey--text" style="font-size:14px" >
                                        We're very happy to see you again on your personal dashboard
                                    </span>
                                </v-row>
                            </v-col>
                            <v-list-item-title>
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-btn style="background: #33BDF1;" class="white--text"  @click="redirectPersonalTask">
                            View Task
                        </v-btn>
                        <!-- <v-btn color="black darken-3 " outlined >
                            Log out
                        </v-btn> -->
                    </v-list-item>
                </v-list>
            </v-card>
        </v-row>

        <v-row >
            <v-col class="pl-0 pr-3" cols="12" sm="4">
                <v-card style="border-radius:5px;overflow:auto" outlined elevation="1" height="500px">
                    <v-card-title class='d-flex justify-center font-weight-bold'>
                        Announcement
                    </v-card-title>
                    <v-list height="83%"   class="mx-5" style="border:1px solid; border-color:#E3E6EB">
                        <v-list-item>
                            <v-icon size="10" class="mx-2" color="primary">
                                bi-circle-fill
                            </v-icon>
                            <span class="font-weight-bold text-h7">
                                Upcoming Modules
                            </span>
                        </v-list-item>
                        <v-list-item style="left:5%" class="grey--text">
                            <v-col>
                                <v-row>
                                    <span>
                                        1. Refund
                                    </span>

                                </v-row>
                                <v-row>
                                    <span>
                                        2. Freebies
                                    </span>

                                </v-row>
                                <v-row>
                                    <span>
                                        3. HRDC
                                    </span>

                                </v-row>

                                <v-row>
                                    <span>
                                        4. First joiner
                                    </span>
                                </v-row>
                            </v-col>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-col>
            <v-col cols="12" sm="8" class="px-0">
                <v-card  style="border-radius:5px;overflow:auto" outlined elevation="1" height="500px" class="">
                    <v-card-title class="d-flex justify-center font-weight-bold">
                        Performance Summary - Filter Years
                    </v-card-title>
                    <div class="px-5">
                        <PagePerformanceChart />
                    </div>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" sm="12" class="px-0 ">
                <v-card  elevation="1" class="px-5" outlined >
                    <v-card-title class="d-flex justify-center font-weight-bold pb-0 mb-0">
                            Top 10 Trending Video - Filter Days
                    </v-card-title>
                    <div class="px-5">
                        <PageTopVideosChart />
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import PagePerformanceChart from '../chart/PagePerformanceChart.vue';
import PageTopVideosChart from '../chart/PageTopVideosChart.vue';

export default{
    components: { PagePerformanceChart,PageTopVideosChart},

    methods:{
        redirectPersonalTask(){
            this.$store.commit('updateCurrentTitle','My Tasks')
            this.$router.push({name:'PageTaskListUser',params:{id:this.$store.getters.getUserId}});
        },
    }
}
</script>